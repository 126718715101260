import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

// Containers
const DefaultContainer = () => import("@/containers/DefaultContainer");
const Page404 = () => import("@/views/Page404");
const Login = () => import("@/views/Login");
const newPerson = () => import("@/components/hr/AddPerson");
const AddService = () => import("@/components/services/AddService");
const ChangePassword = () => import("@/components/setting/ChangePassword");
const EditHomeCare = () => import("@/views/EditHomeCare");
const Patients = () => import("@/views/Patients");
const Report = () => import("@/views/Report");
const Requests = () => import("@/views/Requests");
const NewDoctor = () => import("@/views/NewDoctor");
const NewNurse = () => import("@/views/NewNurse");
const Doctors = () => import("@/views/Doctors");
const NewPatient = () => import("@/views/NewPatient");
const HomeCares = () => import("@/views/HomeCares");
const ShiftReport = () => import("@/views/ShiftReport");
const EditRequest = () => import("@/views/EditRequest");
const EditPatient = () => import("@/views/EditPatient");
const RenderedServices = () => import("@/views/renderedServices");
const LaboratoryRequests = () => import("@/views/LaboratoryRequests");
const LabRequestId = () => import("@/views/LabRequestId");
const SubmitFeedback = () => import("@/views/SubmitFeedback");
const ChangeLog = () => import("@/views/ChangeLog");
const DoctorsList = () => import("@/views/DoctorsList");
const EmployeeChangePass = () => import("@/views/EmployeeChangePass");
const DentistRequests = () => import("@/views/DentistRequests");
const DentistryReport = () => import("@/views/DentistryReport");
// Reception PAGES
const NewRequest = () => import("@/views/Reception/NewRequest");
const NewHomeCare = () => import("@/views/Reception/NewHomeCare");
const ChangeShiftDetails = () => import("@/views/Reception/ChangeShiftDetails");
const Dashboard = () => import("@/views/Reception/Dashboard");
const ReceptionLaboratoryRequests = () =>
  import("@/views/Reception/LaboratoryRequests");
const ReceptionHomeCaresFinancial = () =>
  import("@/views/Reception/HomeCaresFinancial");
const TaminInsurances = () => import("@/views/TaminInsurances");
const MilitaryInsurances = () => import("@/views/MilitaryInsurances");
const IncompleteMilitary = () => import("@/views/IncompleteMilitary");
const IncompleteTamin = () => import("@/views/IncompleteTamin");
const ClinicRequestFinancials = () =>
  import("@/views/Reception/ClinicFinancials");
const SubmitBooking = () => import("@/views/Reception/SubmitBooking");
const SecondVisits = () => import("@/views/Reception/SecondVisits");
const ExpertiseDoctors = () => import("@/views/Reception/ExpertiseDoctors");
const ExpertiseBooking = () => import("@/views/Reception/ExpertiseBooking");

// Admin PAGES
const adminReceptions = () => import("@/views/Admin/Receptions");
const adminNewReception = () => import("@/views/Admin/NewReception");
const adminReportProcess = () => import("@/views/Admin/ReportProccess");
const adminEditDoctor = () => import("@/views/Admin/EditDoctor");
const adminShowDoctor = () => import("@/views/Admin/ShowDoctor");
const adminEditReception = () => import("@/views/Admin/EditReception");
const adminEditNurse = () => import("@/views/Admin/EditNurse");
const adminServices = () => import("@/views/Admin/Services");
const adminDentalServices = () => import("@/views/Admin/DentalServices");
const adminHomeServices = () => import("@/views/Admin/HomeServices");
const adminEditService = () => import("@/views/Admin/EditService");
const adminEditDentalService = () => import("@/views/Admin/EditDentalService");
const adminNewService = () => import("@/views/Admin/NewService");
const adminNewDentalService = () => import("@/views/Admin/NewDentalService");
const adminEditHomeService = () => import("@/views/Admin/EditHomeService");
const adminNewHomeService = () => import("@/views/Admin/NewHomeService");
const adminInsurance = () => import("@/views/Admin/Insurance");
const adminEditInsurance = () => import("@/views/Admin/EditInsurance");
const adminEmployees = () => import("@/views/Admin/Employees");
const adminNewEmployee = () => import("@/views/Admin/NewEmployee");
const adminEditServant = () => import("@/views/Admin/EditServant");
const adminActivityHours = () => import("@/views/Admin/ActivityHours");
const GlobalChart = () => import("@/views/Admin/GlobalChart");
const AdminDashboard = () => import("@/views/Admin/Dashboard");
const adminAllEmployees = () => import("@/views/Admin/AllEmployees");
const adminAllInactiveEmployees = () =>
  import("@/views/Admin/AllInactiveEmployees");
const adminLeaveList = () => import("@/views/Admin/LeaveList");
const adminPayments = () => import("@/views/Admin/Payments");
const adminReceives = () => import("@/views/Admin/Receives");
const adminReceivedLetters = () => import("@/views/Admin/ReceivedLetters");
const adminSentLetters = () => import("@/views/Admin/SentLetters");
const AdminTaminInsurances = () => import("@/views/TaminInsurances");
const AdminMilitaryInsurances = () => import("@/views/MilitaryInsurances");
const AdminIncompleteMilitary = () => import("@/views/IncompleteMilitary");
const AdminIncompleteTamin = () => import("@/views/IncompleteTamin");
const AdminFeedBacks = () => import("@/views/Admin/Feedbacks");
const AdminDoctorTimes = () => import("@/views/Admin/DoctorTimes");
const AdminDentistryServices = () => import("@/views/Admin/DentistryServices");
const NewPaySlip = () => import("@/views/Admin/NewPaySlip");
const EditPaySlip = () => import("@/views/Admin/EditPaySlip");
const ReportsManagement = () => import("@/views/Admin/ReportsManagement");
const DoctorsReports = () => import("@/views/Admin/DoctorsReports");
const NursesReports = () => import("@/views/Admin/NursesReports");
const ServicesReports = () => import("@/views/Admin/ServicesReports");
const InsurancesReports = () => import("@/views/Admin/InsurancesReports");
const AdminPaymentMethods = () => import("@/views/Admin/PaymentMethods");

//Lab Reception pages
const LabReceptionNewRequest = () => import("@/views/LabReception/NewRequest");
const LabReceptionAnswer = () =>
  import("@/views/LabReception/LabReceptionAnswer");

//Dentistry Nurse Pages
const DentistryNurseDashboard = () =>
  import("@/views/DentistryNurse/Dashboard");
const DentalNewRequest = () => import("@/views/DentistryNurse/NewRequest");
const DentistryFinancials = () =>
  import("@/views/DentistryNurse/DentistryFinancials");
const DentistsList = () => import("@/views/DentistryNurse/DentistsList");
const DentistBooking = () => import("@/views/DentistryNurse/SubmitBooking");
const DentistryShiftReport = () => import("@/views/DentistryNurse/ShiftReport");

//Doctor pages
const DoctorDashboard = () => import("@/views/Doctor/Dashboard");
const VisitsList = () => import("@/views/VisitsList");
const ShiftDocVisits = () => import("@/views/Doctor/ShiftDocVisits");
const ReservedVisits = () => import("@/views/Doctor/ReservedVisits");
const VisitDetails = () => import("@/views/VisitDetails");
//Nurse pages
const NurseDashboard = () => import("@/views/Nurse/Dashboard");

//Patient Booking
const PatientLogin = () => import("@/views/PatientPanel/Login");
const PatientPanelMain = () => import("@/views/PatientPanel/MainPage");
const PatientNewRequest = () => import("@/views/PatientPanel/NewRequest");
const PatientsRequests = () => import("@/views/PatientsRequests");
const DentistryPatientsRequests = () =>
  import("@/views/DentistryPatientsRequests");

//Secretariat
const DefaultSecretariat = () => import("@/containers/DefaultSecretariat");
const SecretariatDash = () => import("@/views/Secretariat/Dashboard");
// ----- Inbox -----
const SecretariatInbox = () => import("@/views/Secretariat/Inbox/InboxLetters");
const InboxLetterId = () => import("@/views/Secretariat/Inbox/InboxLetterId");
const InboxRelatedLetters = () =>
  import("@/views/Secretariat/Inbox/InboxRelatedLetters");
const InboxNewLetter = () => import("@/views/Secretariat/Inbox/InboxNewLetter");
const InboxReplyLetter = () => import("@/views/Secretariat/Inbox/InboxReply");
const InboxEditLetter = () => import("@/views/Secretariat/Inbox/InboxEdit");

// ----- Outbox -----
const SecretariatOutbox = () =>
  import("@/views/Secretariat/Outbox/OutboxLetters");
const OutboxLetterId = () =>
  import("@/views/Secretariat/Outbox/OutboxLetterId");
const OutboxRelatedLetters = () =>
  import("@/views/Secretariat/Outbox/OutboxRelatedLetters");
const OutboxNewLetter = () =>
  import("@/views/Secretariat/Outbox/OutboxNewLetter");
const OutboxReplyLetter = () =>
  import("@/views/Secretariat/Outbox/OutboxReply");
const OutboxEditLetter = () => import("@/views/Secretariat/Outbox/OutboxEdit");

// ----- Internal -----
const SecretariatInternal = () =>
  import("@/views/Secretariat/Internal/InternalLetters");
const InternalLetterId = () =>
  import("@/views/Secretariat/Internal/InternalLetterId");
const InternalRelatedLetters = () =>
  import("@/views/Secretariat/Internal/InternalRelatedLetters");
const InternalNewLetter = () =>
  import("@/views/Secretariat/Internal/InternalNewLetter");
const InternalReplyLetter = () =>
  import("@/views/Secretariat/Internal/InternalReply");
const InternalEditLetter = () =>
  import("@/views/Secretariat/Internal/InternalEdit");

// ----- AssignedLetters -----
const AssignedLetters = () => import("@/views/Secretariat/AssignedLetters");
// ----- Contacts --------
const Contacts = () => import("@/views/Secretariat/Contacts");

// ----- Signature -----
const WaitingList = () =>
  import("@/views/Secretariat/Signature/WaitingLettersList");
const SubmitSignature = () =>
  import("@/views/Secretariat/Signature/SubmitSignature");

// ----- Reminders -----
const RemindersList = () => import("@/views/Secretariat/RemindersList");
const RemindersArchiveList = () =>
  import("@/views/Secretariat/RemindersArchive");

// ----- Drafts -----
const DraftsList = () => import("@/views/Secretariat/Drafts/DraftLettersList");
const CompleteDraft = () => import("@/views/Secretariat/Drafts/CompleteDraft");

// ----- Saved Letters -----
const MarkedLetters = () => import("@/views/Secretariat/MarkedLetters");

// ----- Archives -----
const ArchivesList = () => import("@/views/Secretariat/ArchivesList");

// ----- Templates -----
const Templates = () => import("@/views/Secretariat/Templates");

//splash
const Splash = () => import("@/views/Splash");

export default new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),

  routes: [
    // Reception Pages
    {
      path: "/reception",
      redirect: "/reception/dashboard",
      name: "خانه",
      component: DefaultContainer,
      children: [
        {
          path: "/reception/dashboard",
          name: "داشبورد پذیرش",
          component: Dashboard,
        },
        {
          path: "/reception/Patients",
          name: "مدیریت بیماران",
          component: Patients,
        },
        {
          path: "/reception/shiftDetails",
          name: "تغییر اطلاعات شیفت",
          component: ChangeShiftDetails,
        },
        {
          path: "/reception/DoctorsList",
          name: "نوبت دهی",
          component: DoctorsList,
        },
        {
          path: "/reception/SubmitBooking/:id",
          name: "ثبت نوبت دهی",
          component: SubmitBooking,
        },
        {
          path: "/reception/SecondVisits",
          name: "ویزیت‌های مجدد",
          component: SecondVisits,
        },
        {
          path: "/reception/ExpertiseDoctors",
          name: "پزشکان هر تخصص",
          component: ExpertiseDoctors,
        },
        {
          path: "/reception/ExpertiseBooking/:id",
          name: "نوبت دهی",
          component: ExpertiseBooking,
        },
        {
          path: "/reception/NewPatient",
          name: "بیمار جدید",
          component: NewPatient,
        },
        {
          path: "/reception/laboratoryfinancial",
          name: "مالی آزمایشگاه",
          component: ReceptionLaboratoryRequests,
        },
        {
          path: "/reception/LaboratoryRequests",
          name: " درخواست های آزمایشگاه",
          component: LaboratoryRequests,
        },
        {
          path: "/reception/DentistRequests",
          name: " درخواست های دندانپزشکی",
          component: DentistRequests,
        },
        {
          path: "/reception/homeCaresFinancials",
          name: "مدیریت مالی خدمات در منزل",
          component: ReceptionHomeCaresFinancial,
        },
        {
          path: "/reception/ClinicFinancials",
          name: "مدیریت مالی خدمات درمانگاه",
          component: ClinicRequestFinancials,
        },
        {
          path: "/reception/editPatient/:id",
          name: "ویرایش بیمار",
          component: EditPatient,
        },
        {
          path: "/reception/doctors/",
          name: "مدیریت کادر درمان",
          component: Doctors,
        },
        {
          path: "/reception/report/",
          name: "گزارش کادر درمان",
          component: Report,
        },
        {
          path: "/reception/newDoctor/",
          name: "پزشک جدید",
          component: NewDoctor,
        },
        {
          path: "/reception/newNurse/",
          name: "پرستار جدید",
          component: NewNurse,
        },
        {
          path: "/reception/Requests",
          name: "مدیریت درخواست‌ها",
          component: Requests,
        },
        {
          path: "/reception/newRequest",
          name: "درخواست جدید",
          component: NewRequest,
        },
        {
          path: "/reception/editRequest/:id",
          name: "ویرایش درخواست",
          component: EditRequest,
        },

        {
          path: "/reception/shiftReport/:id",
          name: "گزارش شیفت",
          component: ShiftReport,
        },
        {
          path: "/reception/RenderedServices",
          name: "دریافت خدمات",
          component: RenderedServices,
        },
        {
          path: "/reception/NewHomeCare",
          name: "ثبت درخواست",
          component: NewHomeCare,
        },
        {
          path: "/reception/EditHomeCare/:id",
          name: " مشاهده درخواست در منزل",
          component: EditHomeCare,
        },
        {
          path: "/reception/HomeCares",
          name: "مدیریت درخواست ها",
          component: HomeCares,
        },
        {
          path: "/reception/changePassword",
          name: "تغییر رمز عبور",
          component: ChangePassword,
        },
        {
          path: "/TaminInsurances",
          name: "بیمه‌های تامین اجتماعی",
          component: TaminInsurances,
        },
        {
          path: "/MilitaryInsurances",
          name: "بیمه‌های نیرو‌های مسلح",
          component: MilitaryInsurances,
        },
        {
          path: "/IncompleteTamin",
          name: "گزارش‌‌ها ناقص تامین اجتماعی",
          component: IncompleteTamin,
        },
        {
          path: "/IncompleteMilitary",
          name: "گزارش‌‌ها ناقص نیروهای مسلح",
          component: IncompleteMilitary,
        },
        {
          path: "/SubmitFeedback",
          name: "ثبت بازخورد",
          component: SubmitFeedback,
        },
        {
          path: "/reception/changePassword",
          name: "تغییر رمز عبور",
          component: ChangePassword,
        },
        {
          path: "/reception/changelog",
          name: "تغییرات هر نسخه",
          component: ChangeLog,
        },
        {
          path: "/reception/patientsRequests",
          name: "درخواست‌های بیماران",
          component: PatientsRequests,
        },
      ],
    },
    // Admin Pages
    {
      path: "/admin",
      redirect: "/admin/dashboard",
      name: " خانه",
      component: DefaultContainer,
      children: [
        {
          path: "/admin/dashboard/",
          name: "پیشخوان",
          component: AdminDashboard,
        },
        {
          path: "/admin/newPatient/",
          name: "بیمار‌جدید",
          component: NewPatient,
        },
        {
          path: "/admin/editPatient/:id",
          name: "‌ویرایش بیمار",
          component: EditPatient,
        },
        {
          path: "/admin/patients/",
          name: "‌مدیریت بیماران",
          component: Patients,
        },
        {
          path: "/admin/doctors/",
          name: "مدیریت کادر درمان",
          component: Doctors,
        },
        {
          path: "/admin/receptions/",
          name: "مدیریت کادر پذیرش",
          component: adminReceptions,
        },
        {
          path: "/admin/report/",
          name: "گزارش کادر درمان",
          component: Report,
        },
        {
          path: "/admin/processreport/",
          name: "گزارش عملکرد",
          component: adminReportProcess,
        },
        {
          path: "/admin/newDoctor/",
          name: "پزشک جدید",
          component: NewDoctor,
        },
        {
          path: "/admin/newReception/",
          name: "پذیرش جدید",
          component: adminNewReception,
        },
        {
          path: "/admin/newPerson/",
          name: "افزودن نیروی انسانی",
          component: newPerson,
        },
        {
          path: "/admin/AddService/",
          name: "افزودن سرویس",
          component: AddService,
        },
        {
          path: "/admin/newNurse/",
          name: "پرستار جدید",
          component: NewNurse,
        },
        {
          path: "/admin/editDoctor/:id",
          name: "اطلاعات پزشک",
          component: adminEditDoctor,
        },
        {
          path: "/admin/showDoctor/:id",
          name: "درآمد حاصل از خدمات",
          component: adminShowDoctor,
        },
        {
          path: "/admin/editReception/:id",
          name: "اطلاعات کارمند پذیرش",
          component: adminEditReception,
        },
        {
          path: "/admin/editNurse/:id",
          name: "اطلاعات پرستار",
          component: adminEditNurse,
        },
        {
          path: "/admin/services/",
          name: "مدیریت خدمات",
          component: adminServices,
        },
        {
          path: "/admin/dentalServices/",
          name: "خدمات دندانپزشکی",
          component: adminDentalServices,
        },
        {
          path: "/admin/renderedServices/",
          name: " خدمات ارائه شده",
          component: RenderedServices,
        },
        {
          path: "/admin/editService/:id",
          name: "ویرایش خدمت",
          component: adminEditService,
        },
        {
          path: "/admin/editDentalService/:id",
          name: "ویرایش خدمت دندانپزشکی",
          component: adminEditDentalService,
        },

        {
          path: "/admin/newService/",
          name: "خدمت جدید",
          component: adminNewService,
        },
        {
          path: "/admin/newDentalService/",
          name: "خدمت جدید دندانپزشکی",
          component: adminNewDentalService,
        },

        {
          path: "/admin/editHomeService/:id",
          name: "مشاهده خدمت در منزل",
          component: adminEditHomeService,
        },
        {
          path: "/admin/newHomeService/",
          name: "خدمت در منزل جدید",
          component: adminNewHomeService,
        },
        {
          path: "/admin/Requests/",
          name: "درخواست‌ها",
          component: Requests,
        },
        {
          path: "/admin/editHomeCare/:id",
          name: "مشاهده خدمت در منزل",
          component: EditHomeCare,
        },
        {
          path: "/admin/HomeCares/",
          name: "درخواست‌ها",
          component: HomeCares,
        },
        {
          path: "/admin/HomeServices/",
          name: "مدیریت خدمات در منزل",
          component: adminHomeServices,
        },
        {
          path: "/admin/Feedbacks/",
          name: "لیست بازخورد‌ها",
          component: AdminFeedBacks,
        },
        {
          path: "/admin/editRequest/:id",
          name: "ویرایش درخواست ها",
          component: EditRequest,
        },
        {
          path: "/admin/shiftReport/:id",
          name: "گزارش شیفت",
          component: ShiftReport,
        },
        {
          path: "/SubmitFeedback",
          name: "ثبت بازخورد",
          component: SubmitFeedback,
        },
        {
          path: "/admin/insurance/",
          name: "مدیریت بیمه",
          component: adminInsurance,
        },
        {
          path: "/admin/editInsurance/:id",
          name: "ویرایش بیمه",
          component: adminEditInsurance,
        },
        {
          path: "/admin/changePassword",
          name: "تغییر رمز عبور",
          component: ChangePassword,
        },
        {
          path: "/admin/employees",
          name: "کارمندان خدمات",
          component: adminEmployees,
        },
        {
          path: "/admin/newEmployee",
          name: "کارمند خدمات جدید",
          component: adminNewEmployee,
        },
        {
          path: "/admin/EditServant/:id",
          name: "ویرایش اطلاعات کارمند خدمات",
          component: adminEditServant,
        },
        {
          path: "/admin/allEmployees",
          name: "کارمندان",
          component: adminAllEmployees,
        },
        {
          path: "/admin/allInactiveEmployees",
          name: "کارمندان غیرفعال",
          component: adminAllInactiveEmployees,
        },
        {
          path: "/admin/activityHours",
          name: "گزارش ساعات فعالیت",
          component: adminActivityHours,
        },
        {
          name: "لیست مرخصی‌ها",
          path: "/admin/leaveList",
          component: adminLeaveList,
        },
        {
          path: "/admin/DoctorsList",
          name: "مدیریت نوبت دهی",
          component: DoctorsList,
        },
        {
          path: "/admin/DoctorTimes/:id",
          name: "زمان‌های پزشک",
          component: AdminDoctorTimes,
        },
        {
          name: "مدیریت پرداخت‌ها",
          path: "/admin/payments",
          component: adminPayments,
        },
        {
          name: "مدیریت دریافت‌ها",
          path: "/admin/receives",
          component: adminReceives,
        },
        {
          name: "نامه‌های ارسالی",
          path: "/admin/sentLetters",
          component: adminSentLetters,
        },
        {
          name: "نامه‌های دریافتی",
          path: "/admin/receivedLetters",
          component: adminReceivedLetters,
        },
        {
          path: "/admin/GlobalChart",
          name: "نمودار",
          component: GlobalChart,
        },

        {
          path: "/admin/LaboratoryRequests",
          name: " درخواست های آزمایشگاه",
          component: LaboratoryRequests,
        },
        {
          path: "/admin/labRequestId/:id",
          name: "جزییات آزمایش  ",
          component: LabRequestId,
        },
        {
          path: "/TaminInsurances",
          name: "بیمه‌های تامین اجتماعی",
          component: AdminTaminInsurances,
        },
        {
          path: "/MilitaryInsurances",
          name: "بیمه‌های نیرو‌های مسلح",
          component: AdminMilitaryInsurances,
        },
        {
          path: "/IncompleteTamin",
          name: "گزارش‌‌ها ناقص تامین اجتماعی",
          component: AdminIncompleteTamin,
        },
        {
          path: "/IncompleteMilitary",
          name: "گزارش‌‌ها ناقص نیروهای مسلح",
          component: AdminIncompleteMilitary,
        },
        {
          path: "/admin/changelog",
          name: "تغییرات هر نسخه",
          component: ChangeLog,
        },
        {
          path: "/admin/DentistRequests",
          name: " درخواست های دندانپزشکی",
          component: DentistRequests,
        },
        {
          path: "/admin/DentistryServices",
          name: "سرویس‌های ارائه شده دندانپزشکی",
          component: AdminDentistryServices,
        },
        {
          path: "/admin/dentistryShiftReport/:id",
          name: "گزارش شیفت",
          component: DentistryShiftReport,
        },
        {
          path: "/admin/dentistryReport",
          name: "مدیریت مالی",
          component: DentistryReport,
        },
        {
          path: "/admin/patientsRequests",
          name: "درخواست‌های بیماران",
          component: PatientsRequests,
        },
        {
          path: "/admin/dentistryPatientsRequests",
          name: "درخواست‌های دندانپزشکی بیماران",
          component: DentistryPatientsRequests,
        },
        {
          path: "/admin/newPaySlip/:id",
          name: "ثبت فیش حقوقی",
          component: NewPaySlip,
        },
        {
          path: "/admin/editPaySlip/:id",
          name: "ویرایش فیش حقوقی",
          component: EditPaySlip,
        },
        {
          path: "/admin/ReportsManagement",
          name: "گزارش‌های مدیریتی",
          component: ReportsManagement,
        },
        {
          path: "/admin/doctorsReports",
          name: "گزارش پزشکان",
          component: DoctorsReports,
        },
        {
          path: "/admin/nursesReports",
          name: "گزارش پرستاران",
          component: NursesReports,
        },
        {
          path: "/admin/servicesReports",
          name: "گزارش خدمات ارائه شده",
          component: ServicesReports,
        },
        {
          path: "/admin/insurancesReports",
          name: "گزارش بیمه‌ها",
          component: InsurancesReports,
        },
        {
          path: "/admin/paymentMethods",
          name: "مدیریت روش‌های پرداخت",
          component: AdminPaymentMethods,
        },
      ],
    },
    // Lab Reception pages
    {
      path: "/labReception",
      redirect: "/labReception/LaboratoryRequests",
      name: " درخواست های آزمایشگاه",
      component: DefaultContainer,
      children: [
        {
          path: "/labReception/NewRequest",
          name: " درخواست جدید",
          component: LabReceptionNewRequest,
        },
        {
          path: "/labReception/LaboratoryRequests",
          name: " درخواست های آزمایشگاه",
          component: LaboratoryRequests,
        },
        {
          path: "/labReception/labReceptionAnswer",
          name: " جوابدهی",
          component: LabReceptionAnswer,
        },
        {
          path: "/labReception/labRequestId/:id",
          name: "جزییات آزمایش  ",
          component: LabRequestId,
        },
        {
          path: "/SubmitFeedback",
          name: "ثبت بازخورد",
          component: SubmitFeedback,
        },
        {
          path: "/labReception/changelog",
          name: "تغییرات هر نسخه",
          component: ChangeLog,
        },
      ],
    },
    // Dentistry Nurse pages
    {
      path: "/dentistryNurse",
      redirect: "/dentistryNurse/dashboard",
      name: "داشبورد پذیرش داندان‌پزشکی",
      component: DefaultContainer,
      children: [
        {
          path: "/dentistryNurse/dashboard",
          name: "داشبورد پذیرش داندان‌پزشکی",
          component: DentistryNurseDashboard,
        },
        {
          path: "/dentistryNurse/newRequest",
          name: "درخواست جدید دندانپزشکی",
          component: DentalNewRequest,
        },
        {
          path: "/dentistryNurse/dentistryFinancials",
          name: "حساب‌های پرداختنی دندانپزشکی",
          component: DentistryFinancials,
        },
        {
          path: "/dentistryNurse/dentistsList",
          name: "لیست دندانپزشکان",
          component: DentistsList,
        },
        {
          path: "/dentistryNurse/submitBooking/:id",
          name: "نوبت دهی",
          component: DentistBooking,
        },
        {
          path: "/dentistryNurse/patients/",
          name: "‌مدیریت بیماران",
          component: Patients,
        },
        {
          path: "/dentistryNurse/dentistryReport",
          name: "گزارش کادر درمان",
          component: DentistryReport,
        },
        //{
        //  path: "/dentistryNurse/SecondVisits",
        //  name: "ویزیت‌های مجدد",
        //  component: SecondVisits,
        //},
        {
          path: "/dentistryNurse/DentistRequests",
          name: " درخواست های دندانپزشکی",
          component: DentistRequests,
        },
        {
          path: "/dentistryNurse/shiftDetails",
          name: "تغییر اطلاعات شیفت",
          component: ChangeShiftDetails,
        },
        {
          path: "/dentistryNurse/changePassword",
          name: "تغییر رمز عبور",
          component: ChangePassword,
        },
        {
          path: "/dentistryNurse/editRequest/:id",
          name: "ویرایش درخواست",
          component: EditRequest,
        },
        {
          path: "/dentistryNurse/dentistryShiftReport/:id",
          name: "گزارش شیفت",
          component: DentistryShiftReport,
        },
        {
          path: "/dentistryNurse/dentistryPatientsRequests",
          name: "درخواست‌های دندانپزشکی بیماران",
          component: DentistryPatientsRequests,
        },
      ],
    },
    // Dentistry Admin Pages
    {
      path: "/dentistryAdmin",
      redirect: "/dentistryAdmin/dentistryReport",
      name: "مدیریت مالی",
      component: DefaultContainer,
      children: [
        {
          path: "/dentistryAdmin/DentistRequests",
          name: " درخواست های دندانپزشکی",
          component: DentistRequests,
        },
        {
          path: "/dentistryAdmin/dentistryPatientsRequests",
          name: "درخواست‌های دندانپزشکی بیماران",
          component: DentistryPatientsRequests,
        },
        {
          path: "/dentistryAdmin/DentistryServices",
          name: "سرویس‌های ارائه شده دندانپزشکی",
          component: AdminDentistryServices,
        },
        {
          path: "/dentistryAdmin/dentistryShiftReport/:id",
          name: "گزارش شیفت",
          component: DentistryShiftReport,
        },
        {
          path: "/dentistryAdmin/dentistryReport",
          name: "مدیریت مالی",
          component: DentistryReport,
        },
        {
          path: "/dentistryAdmin/editRequest/:id",
          name: "ویرایش درخواست",
          component: EditRequest,
        },
        {
          path: "/dentistryAdmin/changePassword",
          name: "تغییر رمز عبور",
          component: ChangePassword,
        },
        {
          path: "/SubmitFeedback",
          name: "ثبت بازخورد",
          component: SubmitFeedback,
        },
      ],
    },
    //Doctor pages
    {
      path: "/doctor",
      redirect: "/doctor/dashboard",
      name: "",
      component: DefaultContainer,
      children: [
        {
          path: "/doctor/dashboard",
          name: "داشبورد دکتر",
          component: DoctorDashboard,
        },
        {
          path: "/doctor/visitsList",
          name: "ویزیت‌های من",
          component: VisitsList,
        },
        {
          path: "/doctor/visitDetails/:id",
          name: "جزئیات ویزیت",
          component: VisitDetails,
        },
        {
          path: "/doctor/changePassword",
          name: "تغییر رمز عبور",
          component: EmployeeChangePass,
        },
      ],
    },
    //Nurse pages
    {
      path: "/nurse",
      redirect: "/nurse/dashboard",
      name: "",
      component: DefaultContainer,
      children: [
        {
          path: "/nurse/dashboard",
          name: "داشبورد پرستار",
          component: NurseDashboard,
        },
        {
          path: "/nurse/visitsList",
          name: "ویزیت‌های من",
          component: VisitsList,
        },
        {
          path: "/nurse/visitDetails/:id",
          name: "جزئیات ویزیت",
          component: VisitDetails,
        },
        {
          path: "/nurse/changePassword",
          name: "تغییر رمز عبور",
          component: EmployeeChangePass,
        },
      ],
    },
    //Shift Doctor pages
    {
      path: "/doctor",
      redirect: "/doctor/dashboard",
      name: "",
      component: DefaultContainer,
      children: [
        {
          path: "/doctor/dashboard",
          name: "داشبورد دکتر",
          component: DoctorDashboard,
        },
        {
          path: "/doctor/visitsList",
          name: "ویزیت‌های من",
          component: VisitsList,
        },
        {
          path: "/doctor/shiftDocVisits",
          name: "کلیه ویزیت‌ها",
          component: ShiftDocVisits,
        },
        {
          path: "/doctor/visitDetails/:id",
          name: "جزئیات ویزیت",
          component: VisitDetails,
        },
        {
          path: "/doctor/reservedVisits",
          name: "نوبت دهی",
          component: ReservedVisits,
        },
        {
          path: "/doctor/changePassword",
          name: "تغییر رمز عبور",
          component: EmployeeChangePass,
        },
      ],
    },
    //Secretariat pages
    {
      path: "/",
      redirect: localStorage.role ? localStorage.role + "/dashboard" : "/login",
      name: "",
      component: DefaultContainer,
      children: [
        {
          path: "/:role/secretariat/dashboard",
          name: "",
          component: DefaultSecretariat,
          children: [
            {
              path: "/:role/secretariat/dashboard",
              name: "دبیرخانه",
              component: SecretariatDash,
            },
            {
              path: "/:role/secretariat/inbox",
              name: "صندوق ورودی",
              component: SecretariatInbox,
              children: [
                {
                  path: "/:role/secretariat/inbox/letterInfo/:id",
                  name: "مشاهده نامه",
                  component: InboxLetterId,
                  children: [
                    {
                      path: "/:role/secretariat/inbox/letterInfo/relatedLetters/:id",
                      name: "نامه‌های مرتبط",
                      component: InboxRelatedLetters,
                    },
                  ],
                },
                {
                  path: "/:role/secretariat/inbox/newLetter",
                  name: "نامه ورودی جدید",
                  component: InboxNewLetter,
                },
                {
                  path: "/:role/secretariat/inbox/reply/:id",
                  name: "ارسال پاسخ",
                  component: InboxReplyLetter,
                },
                {
                  path: "/:role/secretariat/inbox/edit/:id",
                  name: "ویرایش نامه ورودی",
                  component: InboxEditLetter,
                },
              ],
            },
            {
              path: "/:role/secretariat/outbox",
              name: "صندوق خروجی",
              component: SecretariatOutbox,
              children: [
                {
                  path: "/:role/secretariat/outbox/letterInfo/:id",
                  name: "مشاهده نامه",
                  component: OutboxLetterId,
                  children: [
                    {
                      path: "/:role/secretariat/outbox/letterInfo/relatedLetters/:id",
                      name: "نامه‌های مرتبط",
                      component: OutboxRelatedLetters,
                    },
                  ],
                },
                {
                  path: "/:role/secretariat/outbox/newLetter",
                  name: "نامه خروجی جدید",
                  component: OutboxNewLetter,
                },
                {
                  path: "/:role/secretariat/outbox/reply/:id",
                  name: "ارسال پاسخ",
                  component: OutboxReplyLetter,
                },
                {
                  path: "/:role/secretariat/outbox/edit/:id",
                  name: "ویرایش نامه خروجی",
                  component: OutboxEditLetter,
                },
              ],
            },
            {
              path: "/:role/secretariat/internal",
              name: "صندوق سازمانی",
              component: SecretariatInternal,
              children: [
                {
                  path: "/:role/secretariat/internal/letterInfo/:id",
                  name: "مشاهده نامه",
                  component: InternalLetterId,
                  children: [
                    {
                      path: "/:role/secretariat/internal/letterInfo/relatedLetters/:id",
                      name: "نامه‌های مرتبط",
                      component: InternalRelatedLetters,
                    },
                  ],
                },
                {
                  path: "/:role/secretariat/internal/newLetter",
                  name: "نامه سازمانی جدید",
                  component: InternalNewLetter,
                },
                {
                  path: "/:role/secretariat/internal/reply/:id",
                  name: "ارسال پاسخ",
                  component: InternalReplyLetter,
                },
                {
                  path: "/:role/secretariat/internal/edit/:id",
                  name: "ویرایش نامه سازمانی",
                  component: InternalEditLetter,
                },
              ],
            },
            {
              path: "/:role/secretariat/assignedLetters",
              name: "تخصیص به من",
              component: AssignedLetters,
            },
            {
              path: "/:role/secretariat/contacts",
              name: "مخاطب",
              component: Contacts,
            },
            {
              path: "/:role/secretariat/waitingList",
              name: "درانتظار امضا",
              component: WaitingList,
            },
            {
              path: "/:role/secretariat/waitingList/submitSignature/:id",
              name: "ثبت امضا و ارسال",
              component: SubmitSignature,
            },
            {
              path: "/:role/secretariat/reminders",
              name: "یادآوری پیگیری",
              component: RemindersList,
            },
            {
              path: "/:role/secretariat/reminderArchive",
              name: "تاریخچه پیگیری‌ها",
              component: RemindersArchiveList,
            },
            {
              path: "/:role/secretariat/drafts",
              name: "پیش‌نویس",
              component: DraftsList,
            },
            {
              path: "/:role/secretariat/drafts/CompleteDraft/:id",
              name: "تکمیل پیش‌ نویس",
              component: CompleteDraft,
            },
            {
              path: "/:role/secretariat/markedLetters",
              name: "نشان شده‌ها",
              component: MarkedLetters,
            },
            {
              path: "/:role/secretariat/archives",
              name: "بایگانی",
              component: ArchivesList,
            },
            {
              path: "/:role/secretariat/letterTemplates",
              name: "قالب‌های نامه",
              component: Templates,
            },
          ],
        },
      ],
    },

    // { path: "/unauthorize", component: Page403 },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/Splash",
      name: "Splash",
      component: Splash,
    },
    { path: "*", component: Page404 },
    { path: "/", redirect: "/Splash" },
    //patient booking
    {
      path: "/patientPanel/login",
      name: "PatientLogin",
      component: PatientLogin,
    },
    {
      path: "/patientPanel/newRequest",
      name: "PatientNewRequest",
      component: PatientNewRequest,
    },
    {
      path: "/patientPanel/main",
      name: "PatientPanelMain",
      component: PatientPanelMain,
    },
  ],
});
