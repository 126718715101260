const state = {
  nav: {
    items: [
      {
        name: "ادمین",
        //route: "#",
        icon: "fa fa-home",
        open: false,
        children: [
          {
            name: "پیشخوان",
            route: "/admin/dashboard",
            icon: "fa fa-home",
          },
          {
            name: "بیماران",
            route: "/admin/patients",
            icon: "fa fa-address-card",
          },
          {
            name: "کادر درمان",
            route: "/admin/doctors",
            icon: "fa fa-user-md",
          },
          {
            name: "گزارش کادر درمان",
            route: "/admin/report",
            icon: "fa fa-file-text-o",
          },
          {
            name: "خدمات",
            route: "/admin/services",
            icon: "fa fa-list",
          },

          {
            name: "خدمات ارائه شده",
            route: "/admin/renderedServices",
            icon: "fa fa-list",
          },
          {
            name: "درخواست ها",
            route: "/admin/requests",
            icon: "fa fa-list",
          },
        ],
      },

      {
        name: "بیمار",
        //route: "#",
        icon: "fa fa-home",
        open: false,
        children: [
          {
            name: "خانه",
            route: "/reception/dashboard",
            icon: "fa fa-home",
          },
          {
            name: "فرایندهای درمان",
            route: "/Therapy",
            icon: "fa fa-list",
          },
          {
            name: "آموزش",
            route: "/learning",
            icon: "fa fa-list",
          },
          {
            name: "پشتیبانی",
            route: "/support",
            icon: "fa fa-list",
          },
          {
            name: "ملاقات حضوری",
            route: "/appointment",
            icon: "fa fa-list",
          },
          {
            name: "حساب کاربری",
            route: "/profile",
            icon: "fa fa-list",
          },
        ],
      },
    ],
    receptionItems: [
      {
        name: "خانه",
        route: "/reception/dashboard",
        icon: "fa fa-home",
      },
      {
        name: "مدیریت بیماران",
        route: "/reception/Patients",
        icon: "fa fa-users",
      },
      //{
      //  name: "کادر درمان",
      //  route: "/reception/doctors",
      //  icon: "fa fa-user-md",
      //},

      {
        name: "گزارش کادر درمان",
        route: "/reception/report",
        icon: "fa fa-file-text-o",
      },
      {
        name: "نوبت دهی",
        route: "/reception/DoctorsList",
        icon: "fa fa-calendar-check-o",
      },
      {
        name: "ویزیت‌های مجدد",
        route: "/reception/SecondVisits",
        icon: "fa fa-calendar-plus-o",
      },
      {
        name: "خدمات آزمایشگاه",
        open: false,
        icon: "fa fa-flask",
        children: [
          {
            name: "درخواست‌های آزمایشگاه",
            route: "/reception/LaboratoryRequests",
            icon: "fa fa-flask",
          },
          {
            name: "پرداخت‌های آزمایشگاه",
            route: "/reception/laboratoryfinancial",
            icon: "fa fa-money",
          },
        ],
      },
      {
        name: "خدمات در درمانگاه",
        //route: "#",
        icon: "fa fa-list",
        open: false,
        children: [
          {
            name: "ثبت درخواست",
            route: "/reception/newRequest",
            icon: "fa fa-plus-square-o",
          },
          {
            name: "مدیریت درخواست‌ها",
            route: "/reception/requests",
            icon: "fa fa-check-square-o",
          },
          //{
          //  name: "درخواست‌های بیماران",
          //  route: "/reception/patientsRequests",
          //  icon: "mdi-list-status",
          //},
          {
            name: "خدمات ارائه شده",
            route: "/reception/renderedServices",
            icon: "fa fa-check-square-o",
          },
          {
            name: "مدیریت پرداخت‌ها",
            route: "/reception/ClinicFinancials",
            icon: "fa fa-money",
          },
        ],
      },
      {
        name: "خدمات در منزل",
        open: false,
        //route: "#",
        icon: "fa fa-list",
        children: [
          {
            name: "ثبت درخواست",
            route: "/reception/NewHomeCare",
            icon: "fa fa-plus-square-o",
          },
          {
            name: "مدیریت درخواست‌ها",
            route: "/reception/homeCares",
            icon: "fa fa-check-square-o",
          },
          {
            name: "مدیریت پرداخت‌ها",
            route: "/reception/homeCaresFinancials",
            icon: "fa fa-money",
          },
        ],
      },
      {
        name: "دبیرخانه",
        route: "/reception/secretariat/dashboard",
        icon: "fa fa-envelope",
      },
      {
        name: "مدیریت بیمه‌ها",
        icon: "fa fa-universal-access",
        open: false,
        children: [
          {
            name: "بیمه‌های تامین اجتماعی",
            route: "/TaminInsurances",
            icon: "mdi-doctor",
          },
          {
            name: "لیست ناقص تامین‌اجتماعی",
            route: "/IncompleteTamin",
            icon: "mdi-doctor",
          },
          {
            name: "بیمه‌های نیروهای‌مسلح",
            route: "/MilitaryInsurances",
            icon: "mdi-doctor",
          },
          {
            name: "لیست ناقص نیروهای‌مسلح",
            route: "/IncompleteMilitary",
            icon: "mdi-doctor",
          },
        ],
      },
      {
        name: "تغییر اطلاعات شیفت",
        route: "/reception/shiftDetails",
        icon: "fa fa-cog",
      },
      {
        name: "ثبت بازخورد",
        route: "/SubmitFeedback",
        icon: "fa fa-pencil-square-o",
      },
      {
        name: "تغییر رمز عبور",
        route: "/reception/changePassword",
        icon: "fa fa-lock",
      },
    ],
    adminItems: [
      {
        name: "پیشخوان",
        route: "/admin/dashboard",
        icon: "fa fa-home",
      },
      {
        name: "بیماران",
        route: "/admin/patients",
        icon: "fa fa-users",
      },
      {
        name: "مدیریت منابع انسانی",
        //route: "#",
        icon: "fa fa-users",
        open: false,
        children: [
          {
            name: "افزودن نیرو انسانی",
            route: "/admin/newPerson",
            icon: "fa fa-user-md",
          },
          //{
          //  name: "کادر درمان",
          //  route: "/admin/doctors",
          //  icon: "fa fa-user-md",
          //},
          //{
          //  name: "کادر اداری و پذیرش",
          //  route: "/admin/receptions",
          //  icon: "fa fa-user-circle-o",
          //},
          //{
          //  name: "کارکنان خدمات",
          //  route: "/admin/employees",
          //  icon: "fa fa-users",
          //},
          {
            name: "کارمندان",
            route: "/admin/allEmployees",
            icon: "mdi-account-group-outline",
          },
          {
            name: "گزارش ساعات فعالیت",
            route: "/admin/activityHours",
            icon: "fa fa-clock-o",
          },
          {
            name: "لیست مرخصی‌ها",
            route: "/admin/leaveList",
            icon: "mdi-list-status",
          },
          {
            name: "مدیریت نوبت دهی",
            route: "/admin/DoctorsList",
            icon: "mdi-calendar-clock",
          },
          {
            name: "کارمندان غیرفعال",
            route: "/admin/allInactiveEmployees",
            icon: "mdi-account-off",
          },
        ],
      },
      {
        name: "گزارش‌ها",
        //route: "#",
        icon: "fa fa-file-text",
        open: false,
        children: [
          {
            name: "مدیریت مالی",
            route: "/admin/report",
            icon: "fa fa-file-text-o",
          },
          {
            name: "گزارش روند",
            route: "/admin/processreport",
            icon: "fa fa-bar-chart",
          },
          {
            name: "نمودار",
            route: "/admin/GlobalChart",
            icon: "fa fa-bar-chart",
          },
          {
            name: "گزارش های مدیریتی",
            route: "/admin/reportsManagement",
            icon: "mdi-chart-box-outline",
          },
        ],
      },
      {
        name: "امور مالی",
        //route: "#",
        icon: "fa fa-dollar",
        open: false,
        children: [
          {
            name: "مدیریت پرداخت‌ها",
            route: "/admin/payments",
            icon: "fa fa-money",
          },
          {
            name: "مدیریت دریافت‌ها",
            route: "/admin/receives",
            icon: "fa fa-money",
          },
        ],
      },
      //{
      //  name: "دبیرخانه",
      //  //route: "#",
      //  icon: "fa fa-envelope",
      //  open: false,
      //  children: [
      //    {
      //      name: "نامه‌های ارسالی",
      //      route: "/admin/sentLetters",
      //      icon: "fa fa-file-text",
      //    },
      //    {
      //      name: "نامه‌های دریافتی",
      //      route: "/admin/receivedLetters",
      //      icon: "fa fa-file-text",
      //    },
      //  ],
      //},
      {
        name: "دبیرخانه",
        route: "/admin/secretariat/dashboard",
        icon: "fa fa-envelope",
      },
      {
        name: "درخواست‌های درمانگاه",
        route: "/admin/requests",
        icon: "fa fa-medkit",
      },
      //{
      //  name: "درخواست‌های بیماران",
      //  route: "/admin/patientsRequests",
      //  icon: "mdi-list-status",
      //},
      {
        name: "درخواست‌های در منزل",
        route: "/admin/homeCares",
        icon: "mdi-home-city-outline",
      },
      {
        name: "درخواست‌های آزمایشگاه",
        route: "/admin/laboratoryRequests",
        icon: "fa fa-flask",
      },
      {
        name: "درمانگاه دندانپزشکی",
        icon: "mdi-tooth-outline",
        open: false,
        children: [
          {
            name: "مدیریت درخواست‌ها",
            route: "/admin/dentistRequests",
            icon: "fa fa-list-alt",
          },
          //{
          //  name: "درخواست‌های بیماران",
          //  route: "/admin/dentistryPatientsRequests",
          //  icon: "mdi-list-status",
          //},
          {
            name: "مدیریت مالی",
            route: "/admin/dentistryReport",
            icon: "fa fa-money",
          },
          {
            name: "سرویس‌های ارائه شده",
            route: "/admin/dentistryServices",
            icon: "fa fa-list",
          },
        ],
      },
      {
        name: "مدیریت بیمه‌ها",
        icon: "fa fa-universal-access",
        open: false,
        children: [
          {
            name: "بیمه‌های تامین اجتماعی",
            route: "/TaminInsurances",
            icon: "mdi-doctor",
          },
          {
            name: "لیست ناقص تامین‌اجتماعی",
            route: "/IncompleteTamin",
            icon: "mdi-doctor",
          },
          {
            name: "بیمه‌های نیروهای‌مسلح",
            route: "/MilitaryInsurances",
            icon: "mdi-doctor",
          },
          {
            name: "لیست ناقص نیروهای‌مسلح",
            route: "/IncompleteMilitary",
            icon: "mdi-doctor",
          },
        ],
      },
      {
        name: "سرویس‌های درمانی",
        //route: "#",
        icon: "fa fa-medkit",
        open: false,
        children: [
          {
            name: "افزودن سرویس",
            route: "/admin/addService",
            icon: "fa fa-list",
          },
          {
            name: " سرویس‌های در درمانگاه",
            route: "/admin/services",
            icon: "fa fa-list",
          },
          {
            name: "سرویس‌های دندانپزشکی",
            route: "/admin/dentalServices",
            icon: "fa fa-list",
          },
          {
            name: "سرویس‌های در منزل",
            route: "/admin/HomeServices",
            icon: "fa fa-list",
          },
        ],
      },
      {
        name: "سرویس‌های ارائه شده",
        route: "/admin/renderedServices",
        icon: "fa fa-medkit",
      },
      {
        name: "بازخورد‌ها",
        //route: "#",
        icon: "fa fa-clipboard",
        open: false,
        num: true,
        children: [
          {
            name: "ثبت بازخورد",
            route: "/SubmitFeedback",
            icon: "fa fa-pencil-square-o",
          },
          {
            name: "لیست بازخورد‌ها",
            route: "/admin/Feedbacks",
            icon: "fa fa-th-list",
            num: true,
          },
        ],
      },
      {
        name: "تنظیمات",
        //route: "#",
        icon: "fa fa-cog",
        open: false,
        children: [
          {
            name: "مدیریت بیمه",
            route: "/admin/insurance",
            icon: "fa fa-universal-access",
          },
          {
            name: "تغییر رمز عبور",
            route: "/admin/changePassword",
            icon: "fa fa-lock",
          },
          {
            name: "مدیریت روش‌های پرداخت",
            route: "/admin/paymentMethods",
            icon: "mdi-credit-card-settings-outline",
          },
        ],
      },
    ],
    dentistryAdminItems: [
      {
        name: "مدیریت مالی",
        route: "/dentistryAdmin/dentistryReport",
        icon: "fa fa-money",
      },
      {
        name: "مدیریت درخواست‌ها",
        route: "/dentistryAdmin/dentistRequests",
        icon: "fa fa-list-alt",
      },
      //{
      //  name: "درخواست‌های بیماران",
      //  route: "/dentistryAdmin/dentistryPatientsRequests",
      //  icon: "mdi-list-status",
      //},
      {
        name: "سرویس‌های ارائه شده",
        route: "/dentistryAdmin/dentistryServices",
        icon: "fa fa-list",
      },
      {
        name: "دبیرخانه",
        route: "/dentistryAdmin/secretariat/dashboard",
        icon: "fa fa-envelope",
      },
      {
        name: "ثبت بازخورد",
        route: "/SubmitFeedback",
        icon: "fa fa-pencil-square-o",
      },
      {
        name: "تغییر رمز عبور",
        route: "/dentistryAdmin/changePassword",
        icon: "fa fa-lock",
      },
    ],
    doctorItems: [
      {
        name: "پیشخوان",
        route: "/doctor/dashboard",
        icon: "fa fa-home",
      },
      {
        name: "ویزیت‌های من",
        route: "/doctor/visitsList",
        icon: "fa fa-list-ul",
      },
      {
        name: "دبیرخانه",
        route: "/doctor/secretariat/dashboard",
        icon: "fa fa-envelope",
      },
      {
        name: "تغییر رمز عبور",
        route: "/doctor/changePassword",
        icon: "fa fa-lock",
      },
    ],
    shiftDoctorItems: [
      {
        name: "پیشخوان",
        route: "/doctor/dashboard",
        icon: "fa fa-home",
      },
      {
        name: "ویزیت‌های من",
        route: "/doctor/visitsList",
        icon: "fa fa-list-ul",
      },
      {
        name: "کلیه ویزیت‌ها",
        route: "/doctor/shiftDocVisits",
        icon: "fa fa-th-list",
      },
      {
        name: "نوبت دهی",
        route: "/doctor/reservedVisits",
        icon: "fa fa-calendar-check-o ",
      },
      {
        name: "دبیرخانه",
        route: "/doctor/secretariat/dashboard",
        icon: "fa fa-envelope",
      },
      {
        name: "تغییر رمز عبور",
        route: "/doctor/changePassword",
        icon: "fa fa-lock",
      },
    ],
    nurseItems: [
      {
        name: "پیشخوان",
        route: "/nurse/dashboard",
        icon: "fa fa-home",
      },
      {
        name: "ویزیت‌های من",
        route: "/nurse/visitsList",
        icon: "fa fa-list-ul",
      },
      {
        name: "دبیرخانه",
        route: "/nurse/secretariat/dashboard",
        icon: "fa fa-envelope",
      },
      {
        name: "تغییر رمز عبور",
        route: "/nurse/changePassword",
        icon: "fa fa-lock",
      },
    ],
    managerItems: [
      {
        name: "پیشخوان",
        route: "/admin/dashboard",
        icon: "fa fa-home",
      },
      {
        name: "بیماران",
        route: "/admin/patients",
        icon: "fa fa-users",
      },
      {
        name: "مدیریت منابع انسانی",
        //route: "#",
        icon: "fa fa-users",
        open: false,
        children: [
          {
            name: "افزودن نیرو انسانی",
            route: "/admin/newPerson",
            icon: "fa fa-user-md",
          },
          {
            name: "کادر درمان",
            route: "/admin/doctors",
            icon: "fa fa-user-md",
          },
          {
            name: "کادر اداری و پذیرش",
            route: "/admin/receptions",
            icon: "fa fa-user-circle-o",
          },
          {
            name: "کارکنان خدمات",
            route: "/admin/employees",
            icon: "fa fa-users",
          },
          {
            name: "کارمندان",
            route: "/admin/allEmployees",
            icon: "fa fa-users",
          },
        ],
      },
      {
        name: "گزارش شیفت‌ها",
        route: "/admin/report",
        icon: "fa fa-file-text-o",
      },
      {
        name: "دبیرخانه",
        route: "/admin/secretariat/dashboard",
        icon: "fa fa-envelope",
      },
      {
        name: "درخواست‌های درمانگاه",
        route: "/admin/requests",
        icon: "fa fa-medkit",
      },
      {
        name: "درخواست‌های در منزل",
        route: "/admin/homeCares",
        icon: "mdi-home-city-outline",
      },
      {
        name: "سرویس‌های درمانی",
        //route: "#",
        icon: "fa fa-medkit",
        open: false,
        children: [
          {
            name: "افزودن سرویس",
            route: "/admin/addService",
            icon: "fa fa-list",
          },
          {
            name: " سرویس‌های در درمانگاه",
            route: "/admin/services",
            icon: "fa fa-list",
          },
          {
            name: "سرویس‌های دندانپزشکی",
            route: "/admin/dentalServices",
            icon: "fa fa-list",
          },
          {
            name: "سرویس‌های در منزل",
            route: "/admin/HomeServices",
            icon: "fa fa-list",
          },
        ],
      },
      {
        name: "سرویس‌های ارائه شده",
        route: "/admin/renderedServices",
        icon: "fa fa-medkit",
      },
      {
        name: "ثبت بازخورد",
        route: "/SubmitFeedback",
        icon: "fa fa-pencil-square-o",
      },
      {
        name: "تنظیمات",
        //route: "#",
        icon: "fa fa-cog",
        open: false,
        children: [
          {
            name: "مدیریت بیمه",
            route: "/admin/insurance",
            icon: "fa fa-universal-access",
          },
          {
            name: "تغییر رمز عبور",
            route: "/admin/changePassword",
            icon: "fa fa-lock",
          },
        ],
      },
    ],
    labReceptionItems: [
      {
        name: "درخواست جدید",
        route: "/labReception/newRequest",
        icon: "fa fa-plus-square-o",
      },
      {
        name: "درخواست‌های آزمایشگاه",
        route: "/labReception/laboratoryRequests",
        icon: "fa fa-flask",
      },
      {
        name: " جوابدهی",
        route: "/labReception/labReceptionAnswer",
        icon: "fa fa-tag",
      },
      {
        name: "دبیرخانه",
        route: "/labReception/secretariat/dashboard",
        icon: "fa fa-envelope",
      },
      {
        name: "ثبت بازخورد",
        route: "/SubmitFeedback",
        icon: "fa fa-pencil-square-o",
      },
      {
        name: "تغییر رمز عبور",
        route: "/admin/changePassword",
        icon: "fa fa-lock",
      },
    ],
    dentistryNurseItems: [
      {
        name: "خانه",
        route: "/dentistryNurse/dashboard",
        icon: "fa fa-home",
      },
      {
        name: "مدیریت بیماران",
        route: "/dentistryNurse/Patients",
        icon: "fa fa-users",
      },
      {
        name: "گزارش کادر درمان",
        route: "/dentistryNurse/dentistryReport",
        icon: "fa fa-file-text-o",
      },
      {
        name: "نوبت دهی",
        route: "/dentistryNurse/dentistsList",
        icon: "fa fa-calendar-check-o",
      },
      {
        name: "دبیرخانه",
        route: "/dentistryNurse/secretariat/dashboard",
        icon: "fa fa-envelope",
      },
      //{
      //  name: "ویزیت‌های مجدد",
      //  route: "/dentistryNurse/SecondVisits",
      //  icon: "fa fa-calendar-plus-o",
      //},
      {
        name: "خدمات دندانپزشکی",
        //route: "#",
        icon: "fa fa-list",
        open: false,
        children: [
          {
            name: "ثبت درخواست",
            route: "/dentistryNurse/newRequest",
            icon: "fa fa-plus-square-o",
          },
          {
            name: "مدیریت درخواست‌ها",
            route: "/dentistryNurse/dentistRequests",
            icon: "fa fa-check-square-o",
          },
          //{
          //  name: "درخواست‌های بیماران",
          //  route: "/dentistryNurse/dentistryPatientsRequests",
          //  icon: "mdi-list-status",
          //},
          {
            name: "مدیریت پرداخت‌ها",
            route: "/dentistryNurse/dentistryFinancials",
            icon: "fa fa-money",
          },
        ],
      },
      {
        name: "تغییر اطلاعات شیفت",
        route: "/dentistryNurse/shiftDetails",
        icon: "fa fa-cog",
      },
      {
        name: "ثبت بازخورد",
        route: "/SubmitFeedback",
        icon: "fa fa-pencil-square-o",
      },
      {
        name: "تغییر رمز عبور",
        route: "/dentistryNurse/changePassword",
        icon: "fa fa-lock",
      },
    ],
    secretariat: [
      {
        name: "صفحه اصلی",
        url: "/secretariat/dashboard",
        icon: "$Home",
        open: false,
      },
      {
        name: "صندوق سازمانی",
        url: "/secretariat/internal",
        icon: "$Letters",
        open: false,
      },
      {
        name: "صندوق ورودی",
        url: "/secretariat/inbox",
        icon: "$Inbox",
        open: false,
      },
      {
        name: "صندوق خروجی",
        url: "/secretariat/outbox",
        icon: "$Outbox",
        open: false,
      },
      {
        name: "تخصیص به من",
        url: "/secretariat/assignedLetters",
        icon: "$TickUser",
        open: false,
      },
      {
        name: "در انتظار امضا",
        url: "/secretariat/waitingList",
        icon: "$EditLetter",
        open: false,
      },
      {
        name: "یادآوری پیگیری",
        url: "/secretariat/reminders",
        icon: "$Clock",
        open: false,
      },
      {
        name: "تاریخچه پیگیری‌ها",
        url: "/secretariat/reminderArchive",
        icon: "$Clock",
        open: false,
      },
      {
        name: "پیش‌نویس",
        url: "/secretariat/drafts",
        icon: "$Text",
        open: false,
      },
      {
        name: "نشان شده‌ها",
        url: "/secretariat/markedLetters",
        icon: "$Letters",
        open: false,
      },
      {
        name: "بایگانی",
        url: "/secretariat/archives",
        icon: "$Archive",
        open: false,
      },
      {
        name: "قالب‌‌های نامه",
        url: "/secretariat/lettertemplates",
        icon: "$Template",
      },
    ],
  },
};

const getters = {
  nav: (state) => {
    return state.nav;
  },
};

export default {
  state,
  getters,
};
