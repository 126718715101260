import Vue from "vue";
import Vuetify from "vuetify/lib/framework";


// Icons
import Menu from "../components/icons/Menu.vue";
import Home from "../components/icons/Home.vue";
import Letter from "../components/icons/Letter.vue";
import Members from "../components/icons/Members.vue";
import Clipboard from "../components/icons/Clipboard.vue";
import User from "../components/icons/User.vue";
import Users from "../components/icons/Users.vue";
import Briefcase from "../components/icons/Briefcase.vue";
import Document from "../components/icons/Document.vue";
import Letters from "../components/icons/Letters.vue";
import Inbox from "../components/icons/Inbox.vue";
import Outbox from "../components/icons/Outbox.vue";
import Archive from "../components/icons/Archive.vue";
import EditLetter from "../components/icons/EditLetter.vue";
import Clock from "../components/icons/Clock.vue";
import AddPerson from "../components/icons/AddPerson.vue";
import Pen from "../components/icons/Pen.vue";
import PenSquare from "../components/icons/PenSquare.vue";
import Text from "../components/icons/Text.vue";
import Template from "../components/icons/Template.vue";
import Magnify from "../components/icons/Magnify.vue";
import Close from "../components/icons/Close.vue";
import EditUser from "../components/icons/EditUser.vue";
import TickUser from "../components/icons/TickUser.vue";
import Messages from "../components/icons/Messages.vue";
import Save from "../components/icons/Save.vue";
import Saved from "../components/icons/Saved.vue";
import ArrowLeft from "../components/icons/ArrowLeft.vue";
import ArrowDoubleLeft from "../components/icons/ArrowDoubleLeft.vue";
import ArrowRight from "../components/icons/ArrowRight.vue";
import ArrowDoubleRight from "../components/icons/ArrowDoubleRight.vue";
import ArrowUp from "../components/icons/ArrowUp.vue";
import ArrowDown from "../components/icons/ArrowDown.vue";
import Download from "../components/icons/Download.vue";
import View from "../components/icons/View.vue";
import LetterPlus from "../components/icons/LetterPlus.vue";
import Alarm from "../components/icons/Alarm.vue";
import ClipboardText from "../components/icons/ClipboardText.vue";
import Calendar from "../components/icons/Calendar.vue";
import AddSquare from "../components/icons/AddSquare.vue";
import File from "../components/icons/File.vue";
import NoLetter from "../components/icons/NoLetter.vue";
import NoLetter2 from "../components/icons/NoLetter2.vue";
import NoResult from "../components/icons/NoResult.vue";
import Assignment from "../components/icons/Assignment.vue";
import Document2 from "../components/icons/Document2.vue";
import More from "../components/icons/More.vue";
import NoTemplate from "../components/icons/NoTemplate.vue";
import Trash from "../components/icons/Trash.vue";
import Alert from "../components/icons/Alert.vue";
import NoClock from "../components/icons/NoClock.vue";
import AlertOutline from "../components/icons/AlertOutline.vue";
import EditIcon from "../components/icons/Edit.vue";
import AttachIcon from "../components/icons/Attach.vue";
import SendIcon from "../components/icons/Send.vue";
import ReplyIcon from "../components/icons/Reply.vue";
import EyeShowIcon from "../components/icons/eyeShow.vue";
import Record from "../components/icons/Record.vue";
import Image from "../components/icons/Image.vue";
import ChevronDown from "../components/icons/ChevronDown.vue";
import SquareDots from "../components/icons/SquareDots.vue";
import Copy from "../components/icons/Copy.vue";
import Plus from "../components/icons/Plus.vue";
import VideoCall from "../components/icons/VideoCall.vue";
import Chat from "../components/icons/Chat.vue";
import ScreenOn from "../components/icons/ScreenOn.vue";
import ScreenOff from "../components/icons/ScreenOff.vue";
import CallEnd from "../components/icons/CallEnd.vue";
import VideoOff from "../components/icons/VideoOff.vue";
import VoiceOff from "../components/icons/VoiceOff.vue";
import Voice from "../components/icons/Voice.vue";
import Search from "../components/icons/Search.vue";
import Bag from "../components/icons/Bag.vue";
import SalesInvoice from "../components/icons/SalesInvoice.vue";
import BorderedCheck from "../components/icons/BorderedCheck.vue";
import BorderedKey from "../components/icons/BorderedKey.vue";
import Box from "../components/icons/Box.vue";
import EyeOff from "../components/icons/EyeOff.vue";
import EyeOn from "../components/icons/EyeOn.vue";
import Warehouse from "../components/icons/Warehouse.vue";
import Wallet from "../components/icons/Wallet.vue";
import Customer from "../components/icons/Customer.vue";
import Chart2 from "../components/icons/Chart2.vue";
import BoxChecked from "../components/icons/BoxChecked.vue";
import FinishedBox from "../components/icons/FinishedBox.vue";
import NewInvoice from "../components/icons/NewInvoice.vue";
import Key from "../components/icons/Key.vue";
import Star from "../components/icons/Star.vue";
import Email from "../components/icons/Email.vue";
import Phone from "../components/icons/Phone.vue";
import Edit from "../components/icons/Edit.vue";
import Delete from "../components/icons/Delete.vue";
import Cog from "./../components/icons/Cog.vue";
import idIcon from "../components/icons/idIcon";
Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  theme: {
    themes: {
      light: {
        primary: "#00a7b7",
        secondary: "#7d8ec4",
      },
    },
  },
  icons: {
    iconfont: "mdi",
    values: {
      idIcon: {
        component: idIcon,
      },
      Menu: {
        component: Menu,
      },
      Home: {
        component: Home,
      },
      Letter: {
        component: Letter,
      },
      Members: {
        component: Members,
      },
      Clipboard: {
        component: Clipboard,
      },
      User: {
        component: User,
      },
      Users: {
        component: Users,
      },
      Briefcase: {
        component: Briefcase,
      },
      Document: {
        component: Document,
      },
      Letters: {
        component: Letters,
      },
      Inbox: {
        component: Inbox,
      },
      Outbox: {
        component: Outbox,
      },
      Archive: {
        component: Archive,
      },
      EditLetter: {
        component: EditLetter,
      },
      Clock: {
        component: Clock,
      },
      AddPerson: {
        component: AddPerson,
      },
      Pen: {
        component: Pen,
      },
      PenSquare: {
        component: PenSquare,
      },
      Text: {
        component: Text,
      },
      Template: {
        component: Template,
      },
      Magnify: {
        component: Magnify,
      },
      Close: {
        component: Close,
      },
      EditUser: {
        component: EditUser,
      },
      TickUser: {
        component: TickUser,
      },
      Messages: {
        component: Messages,
      },
      Save: {
        component: Save,
      },
      Saved: {
        component: Saved,
      },
      ArrowLeft: {
        component: ArrowLeft,
      },
      ArrowDoubleLeft: {
        component: ArrowDoubleLeft,
      },
      ArrowRight: {
        component: ArrowRight,
      },
      ArrowDoubleRight: {
        component: ArrowDoubleRight,
      },
      ArrowUp: {
        component: ArrowUp,
      },
      ArrowDown: {
        component: ArrowDown,
      },
      Download: {
        component: Download,
      },
      View: {
        component: View,
      },
      LetterPlus: {
        component: LetterPlus,
      },
      Alarm: {
        component: Alarm,
      },
      ClipboardText: {
        component: ClipboardText,
      },
      Calendar: {
        component: Calendar,
      },
      AddSquare: {
        component: AddSquare,
      },
      File: {
        component: File,
      },
      NoLetter: {
        component: NoLetter,
      },
      NoLetter2: {
        component: NoLetter2,
      },
      NoResult: {
        component: NoResult,
      },
      Assignment: {
        component: Assignment,
      },
      Document2: {
        component: Document2,
      },
      More: {
        component: More,
      },
      NoTemplate: {
        component: NoTemplate,
      },
      Trash: {
        component: Trash,
      },
      Alert: {
        component: Alert,
      },
      AlertOutline: {
        component: AlertOutline,
      },
      NoClock: {
        component: NoClock,
      },
      EditIcon: {
        component: EditIcon,
      },
      AttachIcon: {
        component: AttachIcon,
      },
      SendIcon: {
        component: SendIcon,
      },
      ReplyIcon: {
        component: ReplyIcon,
      },
      EyeShowIcon: {
        component: EyeShowIcon,
      },
      Record: {
        component: Record,
      },
      Image: {
        component: Image,
      },
      ChevronDown: {
        component: ChevronDown,
      },
      SquareDots: {
        component: SquareDots,
      },
      Copy: {
        component: Copy,
      },
      Plus: {
        component: Plus,
      },
      VideoCall: {
        component: VideoCall,
      },
      Chat: {
        component: Chat,
      },
      ScreenOn: {
        component: ScreenOn,
      },
      ScreenOff: {
        component: ScreenOff,
      },
      CallEnd: {
        component: CallEnd,
      },
      VideoOff: {
        component: VideoOff,
      },
      VoiceOff: {
        component: VoiceOff,
      },
      Voice: {
        component: Voice,
      },
      Search: {
        component: Search,
      },
      Bag: {
        component: Bag,
      },
      SalesInvoice: {
        component: SalesInvoice,
      },
      BorderedCheck: {
        component: BorderedCheck,
      },
      BorderedKey: {
        component: BorderedKey,
      },
      Box: {
        component: Box,
      },
      EyeOff: {
        component: EyeOff,
      },
      EyeOn: {
        component: EyeOn,
      },
      Warehouse: {
        component: Warehouse,
      },
      Wallet: {
        component: Wallet,
      },
      Customer: {
        component: Customer,
      },
      Chart2: {
        component: Chart2,
      },
      BoxChecked: {
        component: BoxChecked,
      },
      FinishedBox: {
        component: FinishedBox,
      },
      NewInvoice: {
        component: NewInvoice,
      },
      Key: {
        component: Key,
      },
      Star: {
        component: Star,
      },
      Email: {
        component: Email,
      },
      Phone: {
        component: Phone,
      },
      Edit: {
        component: Edit,
      },
      Delete: {
        component: Delete,
      },
      Cog: {
        component: Cog,
      },
    },
  },
});
